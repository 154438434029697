import React from "react";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBBadge,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBCardFooter,
  MDBBtnGroup,
  MDBTypography,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem,
  MDBInput,
} from "mdb-react-ui-kit";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import ProfileCardNew from "./profile-card";
import { useState } from "react";
import Concent from "./concent";
import axios from "axios";
import Webcam from "react-webcam";
import Footer from "./footer";

export default function Verify() {
  const navigate = useNavigate();
  const [basicModal, setBasicModal] = useState(false);
  const [otp, setotp] = useState("");
  const [otpsent, setotpsent] = useState(false);
  const [aadharNo, setaadharNo] = useState("");
  const [step, setstep] = useState(1);
  const [showCapture, setshowCapture] = useState(false);
  const [imgSrc, setImgSrc] = React.useState(null);
  const sentOtp = () => {};
  const webcamRef = React.useRef(null);

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  const aadharVerify = async (product) => {
    //   setProduct({ ...product });
    //  setotpsent(true);

    //const confirm = window.confirm("Do you want to verify with AAdhar ?");
    if (true) {
      const url =
        "https://30ml.dnsis.in/api/sendAadharOtp?aadhaar_number=663815260068";
      //      aadharNo
      const payload = "";
      const res = await axios.post(url, payload);
      console.log(res.data);

      if (res.data?.transaction_id) {
        setotpsent(true);
        setaadharNo("");
        // let otp = prompt("Please enter Aadhar OTP");
        const ref_id = res.data?.data?.reference_id;
        const url1 =
          "http://30ml.dnsis.in/api/getAadharDetails?otp=" +
          otp +
          "&ref_id=" +
          ref_id;
        if (otp && ref_id) {
          const res2 = await axios.post(url1, payload);
          console.log(res2.data);
        }
      }
    }
  };
  const getAadharDetails = () => {
    setstep(2);
    //navigate("/profile");
  };
  const verifyImg = () => {
    setstep(3);
  };
  const videoConstraints = {
    width: 120,
    height: 120,
    facingMode: "user",
  };

  return (
    <div className="dashboard">
      <header>
        <nav class="navbar navbar-expand-lg bg-body">
          <div class="container-fluid">
            <button
              data-mdb-collapse-init
              class="navbar-toggler"
              type="button"
              data-mdb-target="#navbarExample01"
              aria-controls="navbarExample01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => navigate(`/dash`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </button>
            <div className="d-flex text-center">Verify</div>
            <div className="d-flex text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </div>

            <div class="collapse navbar-collapse" id="navbarExample01">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item active">
                  <a class="nav-link" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Features
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Pricing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    About
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <MDBContainer fluid className="vh-100">
        <div className="d-flex" style={{ justifyContent: "space-around" }}>
          <div style={{ textAlign: "center" }} className={step == 1 && "bold"}>
            1<p>Get aadhar</p>
          </div>
          {/* <div style={{ textAlign: "center" }} className={step == 2 && "bold"}>
            2<p>Verify Otp</p>
          </div> */}
          <div style={{ textAlign: "center" }} className={step == 2 && "bold"}>
            2<p>Capture Photo</p>
          </div>
          <div style={{ textAlign: "center" }} className={step == 3 && "bold"}>
            3<p>Verify Photo</p>
          </div>
        </div>

        <MDBRow>
          {step == 1 && (
            <MDBCol md="4" className="mb-md-0 ">
              <MDBCard>
                <MDBCardBody className=" justify-content-center align-items-center">
                  {!otpsent ? (
                    <div className="">
                      {/* <ProfileCardNew /> */}

                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        // labelClass="text-white"
                        label="Aadhar No"
                        id="formControlLg"
                        type="number"
                        size="lg"
                        onChange={(e) => setaadharNo(e.target.value)}
                      />

                      <MDBBtn
                        size="lg"
                        className="mb-2 w-100"
                        onClick={() => aadharVerify()}
                        disabled={aadharNo.length !== 12}
                      >
                        Get OTP
                      </MDBBtn>
                    </div>
                  ) : (
                    <div className="">
                      {/* <ProfileCardNew /> */}

                      <MDBInput
                        wrapperClass="mb-4 w-100"
                        // labelClass="text-white"
                        value={otp}
                        label="Aadhar OTP"
                        id="formControlLg1"
                        type="text"
                        size="lg"
                        onChange={(e) => setotp(e.target.value)}
                      />

                      <MDBBtn
                        size="lg"
                        className="mb-2 w-100"
                        color="warning"
                        disabled={otp.length !== 6}
                        onClick={() => getAadharDetails()}
                      >
                        Verify With Aadhar
                      </MDBBtn>
                    </div>
                  )}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}

          {step == 2 && (
            <MDBCol md="4" className="mb-md-0 p-3" style={{ bottom: 0 }}>
              <MDBCard>
                <MDBCardBody className="d-flex justify-content-center align-items-center p-0">
                  <div className="card">
                    {imgSrc ? (
                      <>
                        {" "}
                        <img src={imgSrc} />
                        <div className="d-flex justify-content-center">
                          <MDBBtn
                            className="me-1 mt-1"
                            color="link"
                            onClick={() => {
                              setImgSrc("");
                            }}
                          >
                            Retake{" "}
                            <svg
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              height="2em"
                              width="2em"
                            >
                              <path d="M2 12a9 9 0 009 9c2.39 0 4.68-.94 6.4-2.6l-1.5-1.5A6.706 6.706 0 0111 19c-6.24 0-9.36-7.54-4.95-11.95C10.46 2.64 18 5.77 18 12h-3l4 4h.1l3.9-4h-3a9 9 0 00-18 0z" />
                            </svg>
                          </MDBBtn>
                          <MDBBtn
                            className="me-1 mt-1"
                            rounded
                            color="success"
                            onClick={() => {
                              verifyImg();
                            }}
                          >
                            Verify{" "}
                            {/* <svg
                              viewBox="0 0 24 24"
                              fill="currentColor"
                              height="2em"
                              width="2em"
                            >
                              <path d="M17 22v-2h3v-3h2v3.5c0 .39-.16.74-.46 1.04-.3.3-.65.46-1.04.46H17M7 22H3.5c-.39 0-.74-.16-1.04-.46-.3-.3-.46-.65-.46-1.04V17h2v3h3v2M17 2h3.5c.39 0 .74.16 1.04.46.3.3.46.65.46 1.04V7h-2V4h-3V2M7 2v2H4v3H2V3.5c0-.39.16-.74.46-1.04.3-.3.65-.46 1.04-.46H7m3.5 4C13 6 15 8 15 10.5c0 .88-.25 1.7-.69 2.4l3.26 3.26-1.41 1.41-3.26-3.26c-.7.44-1.52.69-2.4.69C8 15 6 13 6 10.5S8 6 10.5 6m0 2a2.5 2.5 0 000 5 2.5 2.5 0 000-5z" />
                            </svg> */}
                          </MDBBtn>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <Webcam
                          audio={false}
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          videoConstraints={videoConstraints}
                        />
                        <div
                          className="me-1 mt-1 text-center"
                          onClick={capture}
                          style={{
                            fontSize: 30,
                            marginTop: "-10px",
                            bottom: 30,
                          }}
                        >
                          <svg
                            viewBox="0 0 512 512"
                            fill="currentColor"
                            height="1em"
                            width="1em"
                          >
                            <path d="M149.1 64.8L138.7 96H64c-35.3 0-64 28.7-64 64v256c0 35.3 28.7 64 64 64h384c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64h-74.7l-10.4-31.2C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 384c-53 0-96-43-96-96s43-96 96-96 96 43 96 96-43 96-96 96z" />
                          </svg>
                        </div>
                        {/* <button onClick={capture}>Capture photo</button> */}
                      </>
                    )}
                  </div>
                  {/* <Concent /> */}
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}
          {step == 3 && (
            <MDBCol md="4" className="mb-md-0 p-3" style={{ bottom: 0 }}>
              <MDBCard>
                <MDBCardBody className="d-flex flex-column justify-content-center align-items-center p-2">
                  <div> Your Aadhar Details Verified</div>
                  <div>
                    {" "}
                    <MDBBtn
                      className="me-1"
                      color="danger"
                      onClick={() => navigate(`/cred`)}
                    >
                      Rejected
                    </MDBBtn>
                    <MDBBtn
                      className="me-1"
                      color="success"
                      onClick={() => navigate(`/cred`)}
                    >
                      Verified
                    </MDBBtn>
                  </div>
                </MDBCardBody>
              </MDBCard>
            </MDBCol>
          )}
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}
