import "./styles.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { services } from "./services";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBCheckbox,
  MDBValidation,
  MDBValidationItem,
  MDBInputGroup,
} from "mdb-react-ui-kit";
export default function Login() {
  const usernameRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [state, setstate] = useState({
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const change = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
    console.log(state);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // navigate(`/dash/${username}`, { replace: true });

    const { email, password } = state;
    const payload = {
      email,
      password,
    };
    if (email && password) {
      const res = await services.signin(payload);
      if (res.data?.userId) {
        console.log(res.data);
        localStorage.setItem("user", JSON.stringify(res.data));
        navigate("/dash");
      }
    }
  };
  useEffect(() => {
    //usernameRef.current.focus();
  }, []);
  const clientId =
    "414565162824-iolb7mcqbt5j4v86evnnenajdim87vhc.apps.googleusercontent.com";

  const onSuccess = (res) => {
    console.log(res);
    // props.setUser(res.profileObj);
  };
  const onFailure = (res) => {
    console.log(res);
    //  alert(`Oops! Some error occured: ${res}`);
  };

  // const googleAuth = useGoogleLogin({
  //   clientId:
  //     "414565162824-iolb7mcqbt5j4v86evnnenajdim87vhc.apps.googleusercontent.com"
  // });

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCard
          className="  mx-auto"
          style={{ borderRadius: "0rem", maxWidth: "400px" }}
        >
          <MDBCardBody className="p-0 d-flex flex-column align-items-center mx-auto w-100">
            <div className="text-center">
              <img
                src="http://legalsip.in/images/logo.jpeg"
                style={{ width: "105px", borderRadius: "50%" }}
                alt="logo"
              />
              <h4 className="mt-1 mb-5 pb-1"></h4>
            </div>

            <p>Legal Drinking Age verification App</p>

            <h2 className="fw-bold mb-2 text-center">Sign in</h2>
            {/* <p className="text-dark-50 mb-3">
                Please enter your login and password!
              </p> */}
            <MDBValidation className="row g-3">
              <MDBValidationItem feedback="Please choose a username." invalid>
                <MDBInput
                  label="Email address"
                  type="email"
                  name="email"
                  size="lg"
                  value={state.email}
                  onChange={change}
                  id="validationCustomUsername"
                  required
                />
              </MDBValidationItem>
              <MDBValidationItem feedback="Please choose a password." invalid>
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Password"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  value={state.password}
                  name="password"
                  onChange={change}
                />
              </MDBValidationItem>
              <p className="small mb-3 pb-lg-2 d-flex justify-content-between">
                <a class="" href="#!">
                  Forgot password?
                </a>
              </p>
              <div className="col-12">
                <MDBBtn
                  size="lg"
                  className="mb-2 w-100"
                  onClick={handleSubmit}
                  type="submit"
                >
                  Login
                </MDBBtn>
              </div>
              <Link to="/signup">
                <MDBBtn
                  size="lg"
                  className="mb-2 w-100"
                  outline
                  color="warning"
                >
                  Signup
                </MDBBtn>
              </Link>
            </MDBValidation>
            <hr className="my-4" />
            <GoogleLogin
              clientId={clientId}
              buttonText="Sign in with google"
              onSuccess={onSuccess}
              onFailure={onFailure}
              cookiePolicy={"single_host_origin"}
              isSignedIn={true}
              className="mb-4 w-100"
            />

            {/* <MDBBtn className="mb-4 w-100" color="danger" size="lg" outline>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#FFC107"
                    d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                  <path
                    fill="#FF3D00"
                    d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
                  ></path>
                  <path
                    fill="#4CAF50"
                    d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
                  ></path>
                  <path
                    fill="#1976D2"
                    d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
                  ></path>
                </svg>
                <MDBIcon fab icon="google" className="mx-2" />
                Sign in with google
              </MDBBtn> */}
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
  );
}
