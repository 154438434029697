import axios from "axios";
import QrScanner from "qr-scanner";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  MDBDropdown,
  MDBDropdownMenu,
  MDBDropdownToggle,
  MDBDropdownItem,
  MDBBtn,
} from "mdb-react-ui-kit";
//import './QRScanner.scss';

const QRScanner = (props) => {
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState("");
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("user"));

  const getData = async (url) => {
    if (url) {
      const res = await axios.get(url);
      console.log(res.data[0]);
      if (res.data?.length) {
        localStorage.setItem("pub", JSON.stringify(res.data[0]));
        navigate("/dash");
      }
    }
  };

  useEffect(() => {
    const video = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        console.log(result);
        getData(result.data);
        setScannedText(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();
    console.log("start");

    return () => {
      console.log(qrScanner);
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  // const qrScanner = new QrScanner(videoElement, (result) =>
  //   console.log('decoded qr code:', result)
  // );

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg bg-body">
          <div className="container-fluid">
            <div className="btn-back" onClick={() => navigate("/dash")}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </div>
            <button
              data-mdb-collapse-init
              className="navbar-toggler"
              type="button"
              data-mdb-target="#navbarExample01"
              aria-controls="navbarExample01"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <small>Scan QR</small>
            </button>

            <MDBDropdown>
              <MDBDropdownToggle tag="a" color="link">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-person-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                  <path
                    fillRule="evenodd"
                    d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                  />
                </svg>
              </MDBDropdownToggle>
              <MDBDropdownMenu>
                <MDBDropdownItem link> {user?.name}</MDBDropdownItem>
                <MDBDropdownItem link>Profile</MDBDropdownItem>
                <MDBDropdownItem link onClick={() => navigate("/login")}>
                  Logout
                </MDBDropdownItem>
                {/* <MDBDropdownItem link>Something else here</MDBDropdownItem> */}
              </MDBDropdownMenu>
            </MDBDropdown>
          </div>
        </nav>
      </header>
      <div className="videoWrapper">
        <video className="qrVideo" ref={videoElementRef} />
      </div>
      {scanned && (
        <MDBBtn className="me-1" color="info" onClick={() => navigate(`/cred`)}>
          Back To DashBoard
        </MDBBtn>
      )}

      {/* <p className="scannedText">SCANNED: {scanned}</p> */}
    </div>
  );
};

export default QRScanner;
