import React, { useEffect, useState } from "react";
import {
  MDBBadge,
  MDBListGroup,
  MDBListGroupItem,
  MDBBtn,
} from "mdb-react-ui-kit";
import { services } from "./services";

export default function HistoryList() {
  const [history, sethistory] = useState(null);
  useEffect(() => {
    getScans();
  }, []);

  const getScans = async () => {
    const res = await services.getQrScansByUser();
    console.log(res.data);
    sethistory(res.data);
  };

  return (
    <MDBListGroup style={{ minWidth: "22rem" }} light>
      {history?.map((row) => (
        <MDBListGroupItem className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              src="https://mdbootstrap.com/img/new/avatars/8.jpg"
              alt=""
              style={{ width: "45px", height: "45px" }}
              className="rounded-circle"
            />
            <div className="ms-3">
              <p className="fw-bold mb-1">{row.pubId}</p>
              <p className="text-muted mb-0">{row.datetime}</p>
            </div>
          </div>
          <MDBBtn size="sm" rounded color="link">
            Map
          </MDBBtn>
        </MDBListGroupItem>
      ))}

      <MDBListGroupItem className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img
            src="https://mdbootstrap.com/img/new/avatars/6.jpg"
            alt=""
            style={{ width: "45px", height: "45px" }}
            className="rounded-circle"
          />
          <div className="ms-3">
            <p className="fw-bold mb-1">Alex Ray</p>
            <p className="text-muted mb-0">alex.ray@gmail.com</p>
          </div>
        </div>
        <MDBBtn size="sm" rounded color="link">
          View
        </MDBBtn>
      </MDBListGroupItem>
      <MDBListGroupItem className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <img
            src="https://mdbootstrap.com/img/new/avatars/7.jpg"
            alt=""
            style={{ width: "45px", height: "45px" }}
            className="rounded-circle"
          />
          <div className="ms-3">
            <p className="fw-bold mb-1">Kate Hunington</p>
            <p className="text-muted mb-0">kate.hunington@gmail.com</p>
          </div>
        </div>
        <MDBBtn size="sm" rounded color="link">
          View
        </MDBBtn>
      </MDBListGroupItem>
    </MDBListGroup>
  );
}
