import React, { useState } from "react";
import {
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";
import { useNavigate } from "react-router-dom";

export default function Concent() {
  const [basicModal, setBasicModal] = useState(true);
  const navigate = useNavigate();
  const toggleOpen = () => setBasicModal(!basicModal);

  return (
    <>
      <MDBBtn onClick={toggleOpen}>Verify With Aadhar </MDBBtn>
      <MDBModal
        open={basicModal}
        onClose={() => setBasicModal(false)}
        tabIndex="-1"
      >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>
                <small>User Concent</small>
              </MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={toggleOpen}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <small>
                We are committed to ensuring the security and accuracy of your
                personal data at every step. To further enhance our verification
                process and provide you with seamless service, we would like to
                request your consent to verify certain details using your Aadhar
                information. Aadhar, as you know, is a secure and
                government-issued identification document. By consenting to this
                verification process, you allow us to cross-reference the
                information you've provided with the details associated with
                your Aadhar card. Your consent is entirely voluntary, and you
                have the right to refuse or withdraw it at any time. Your
                decision will not affect the services we provide to you in any
                way. If you agree to this verification process, please respond
                to this email with the subject line "Consent Granted." If you
                have any questions or concerns regarding this request, please
                don't hesitate to reach out to our support team. Thank you for
                your attention to this matter, and for your continued trust in
                our services.
              </small>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn color="secondary" onClick={toggleOpen}>
                Close
              </MDBBtn>
              <MDBBtn
                className="me-1"
                color="info"
                onClick={() => {
                  toggleOpen();
                  navigate(`/verify`);
                }}
              >
                Proceed
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
