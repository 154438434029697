import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBBadge,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBCardFooter,
  MDBBtnGroup,
  MDBTypography,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBListGroup,
  MDBListGroupItem,
} from "mdb-react-ui-kit";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import ProfileCardNew from "./profile-card";
import { useState } from "react";
import Concent from "./concent";
import Footer from "./footer";

export default function Cred() {
  const navigate = useNavigate();
  const [basicModal, setBasicModal] = useState(false);
  return (
    <div className="dashboard">
      <header>
        <nav class="navbar navbar-expand-lg bg-body">
          <div class="container-fluid">
            <button
              data-mdb-collapse-init
              class="navbar-toggler"
              type="button"
              data-mdb-target="#navbarExample01"
              aria-controls="navbarExample01"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => navigate(`/dash`)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-arrow-left"
                viewBox="0 0 16 16"
              >
                <path
                  fill-rule="evenodd"
                  d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8"
                />
              </svg>
            </button>
            <div className="d-flex text-center">Add Cred</div>
            <div className="d-flex text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-person-circle"
                viewBox="0 0 16 16"
              >
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                <path
                  fill-rule="evenodd"
                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                />
              </svg>
            </div>

            <div class="collapse navbar-collapse" id="navbarExample01">
              <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                <li class="nav-item active">
                  <a class="nav-link" aria-current="page" href="#">
                    Home
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Features
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    Pricing
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#">
                    About
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
      <MDBContainer fluid className="vh-100">
        <MDBRow>
          <MDBCol md="4" className="mb-md-0 p-3"></MDBCol>
          <MDBCol md="4" className="mb-md-0 p-3" style={{ bottom: 0 }}>
            <div>
              <div className="d-flex justify-content-center align-items-center p-2">
                <Concent />
              </div>
            </div>
          </MDBCol>
          {/* <MDBCol md="4" className="mb-md-0 p-3" style={{ bottom: 0 }}>
            <MDBCard>
              <MDBCardBody className="d-flex justify-content-center align-items-center p-2">
                <MDBBtn
                  className="me-1"
                  color="info"
                  onClick={() => navigate(`/cred`)}
                >
                  Verify With PAN Record
                </MDBBtn>
              </MDBCardBody>
            </MDBCard>
          </MDBCol> */}
        </MDBRow>
      </MDBContainer>
      <Footer />
    </div>
  );
}
