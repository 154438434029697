import "./styles.css";
import { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBIcon,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { services } from "./services";
export default function Signup() {
  const usernameRef = useRef();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [state, setstate] = useState("");
  const navigate = useNavigate();

  const change = (e) => {
    const { name, value } = e.target;
    setstate({ ...state, [name]: value });
    console.log(state);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, phone, email, password } = state;
    const payload = {
      name,
      address: "address",
      phone,
      email,
      city: "state",
      password,
    };
    const res = await services.signup(payload);
    if (res.data?.status) {
      console.log(res.data);
      navigate("/login");
    }
    // navigate(`/dash/${username}`, { replace: true });
  };
  useEffect(() => {
    //usernameRef.current.focus();
  }, []);

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center align-items-center h-100">
        <MDBCard
          className="  mx-auto"
          style={{ borderRadius: "0rem", maxWidth: "400px" }}
        >
          <MDBCardBody className="pb-4 d-flex flex-column align-items-center mx-auto w-100">
            <div className="text-center">
              <img
                src="http://legalsip.in/images/logo.jpeg"
                style={{ width: "105px", borderRadius: "50%" }}
                alt="logo"
                className="mb-4"
              />
              {/* <h4 className="mt-1 mb-5 pb-1"></h4> */}
            </div>
            <div className="col-12">
              {/* <p>Legal Drinking Age verification App</p> */}
              <form onSubmit={handleSubmit}>
                <h2 className="fw-bold mb-2 text-center">Sign Up</h2>
                {/* <p className="text-white-50 mb-3">
                Please enter your login and password!
              </p> */}
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Name"
                  id="formControlLg"
                  type="text"
                  size="lg"
                  name="name"
                  onChange={change}
                />
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Email address"
                  id="formControlLg"
                  type="email"
                  size="lg"
                  name="email"
                  onChange={change}
                />
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Mobile"
                  id="formControlLg"
                  type="phone"
                  size="lg"
                  name="phone"
                  onChange={change}
                />
                <MDBInput
                  wrapperClass="mb-4 w-100"
                  label="Password"
                  id="formControlLg"
                  type="password"
                  size="lg"
                  name="password"
                  onChange={change}
                  required
                />

                {/* <p className="small mb-3 pb-lg-2 d-flex justify-content-between">
                <a class="text-white-50" href="#!">
                  Forgot password?
                </a>
              </p> */}

                <MDBBtn size="lg" className="mb-2 w-100" color="warning">
                  Signup
                </MDBBtn>

                <Link to="/login">
                  <MDBBtn
                    size="lg"
                    className="mb-2 w-100"
                    outline
                    color="warning"
                  >
                    Login
                  </MDBBtn>
                </Link>
              </form>
            </div>
            {/* <hr className="my-4" />

              <MDBBtn
                className="mb-4 w-100"
                size="lg"
                style={{ backgroundColor: "#dd4b39" }}
              >
                <MDBIcon fab icon="google" className="mx-2" />
                Sign in with google
              </MDBBtn> */}
          </MDBCardBody>
        </MDBCard>
      </MDBRow>
    </MDBContainer>
  );
}
