import axios from "axios";
const signup = async (payload) => {
  const url = "https://30ml.dnsis.in/api/user/signUp";
  return await axios.post(url, payload);
};
const signin = async (payload) => {
  const url = "https://30ml.dnsis.in/api/user/signIn";
  return await axios.post(url, payload);
};

const getQrScansByUser = async (payload) => {
  const url = "https://30ml.dnsis.in/api/pubs/getQrScansByUser";
  return await axios.post(url, payload);
};

export const services = {
  signup,
  signin,
  getQrScansByUser,
};
