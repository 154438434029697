import "./styles.css";
import { useState, useEffect } from "react";
import Login from "./Login";
import { Routes, Route } from "react-router-dom";
import Home from "./Home";
import PageNotFound from "./PageNotFound";
import Dash from "./Dash";
import Profile from "./Profile";
import Cred from "./cred";
import Verify from "./aadharVerify";
import Signup from "./Signup";
import QRScanner from "./QRScanner/QRScanner";
import { useNavigate, Link } from "react-router-dom";
import History from "./History";

export default function App() {
  // const navigate = useNavigate();
  // useEffect(() => {
  //   navigate("/login");
  //   //usernameRef.current.focus();
  // }, []);
  return (
    <>
      <Routes>
        {/* Routes */}
        <Route index element={<Dash />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />
        {/*Nested Routes using Outlet*/}
        <Route path="dash" element={<Dash />}>
          <Route index element={<Dash />} />
          <Route path=":user" element={<Profile user="USER" />} />
          {/* <Route path=":cred" element={<Cred user="USER" />} /> */}
        </Route>

        <Route path="qrscan" element={<QRScanner />} />
        <Route path="cred" element={<Cred />} />
        <Route path="verify" element={<Verify />} />
        <Route path="history" element={<History />} />
        {/* 404 page */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
      <div></div>
    </>
  );
}
