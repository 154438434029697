import { useNavigate } from "react-router-dom";
import "./styles.css";
export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div className="App">
      <h1>
        <span>4</span>
        <span>0</span>
        <span>4</span>
      </h1>
      <div>
        <button
          style={{ width: 200 }}
          onClick={() => {
            navigate(-1);
          }}
        >
          Go back
        </button>
      </div>
    </div>
  );
}
